// 门店业绩提成方案
import * as API from '@/api/index'

export default {
  //门店提成查询
  EntityCommissionSchemeAll: params => {
    return API.POST('api/entityCommissionScheme/all', params)
  },
  //门店提成添加
  EntityCommissionSchemeCreate: params => {
    return API.POST('api/entityCommissionScheme/create', params)
  },
  //员工列表
  EntityCommissionSchemeAllEmployee: params => {
    return API.POST('api/entityCommissionScheme/allEmployee', params)
  },
  //门店提成详情-条件
  EntityCommissionSchemeCommission: params => {
    return API.POST('api/entityCommissionScheme/commission', params)
  },
  //门店提成详情-员工
  EntityCommissionSchemeEmployee: params => {
    return API.POST('api/entityCommissionScheme/employee', params)
  },
  //门店提成详情-员工计算的门店
  EntityCommissionSchemeEmployeeCalculateEntity: params => {
    return API.POST('api/entityCommissionScheme/employeeCalculateEntity', params)
  },
  //门店提成修改
  EntityCommissionSchemeUpdate: params => {
    return API.POST('api/entityCommissionScheme/update', params)
  },

}
